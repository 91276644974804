import React from "react";
import { IBaseProps } from "../services/interface";
import style from "./css/page.module.css";

interface IProps extends IBaseProps {
  type: number;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  id?: string;
}

function Page(props: IProps) {
  const type = props.type;
  const st =
    "PAGE_" +
    new Date().getTime() +
    "_" +
    parseInt(String(Math.random() * 10000000));
  return (
    <div
      id={props.id || st}
      className={type === 1 ? style.bg : style.notBg}
      style={props.style ? props.style : {}}
    >
      <div className={style.container} style={props.containerStyle || {}}>
        {props.children}
      </div>
    </div>
  );
}

export default Page;
