import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./app";
import "./i18n";
import { createHashRouter, RouterProvider } from "react-router-dom";
import AboutPage from "./views/about-page";
// Roboto font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { SnackbarProvider } from "notistack";

import "./common.css";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);
