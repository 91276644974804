import style from "./css/App.module.css";

import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import Header from "./components/header";
import Index from "./views/index";
import DownloadPage from "./views/download";
import SupportPage from "./views/support-page";
import ForeignSupportPage from "./views/foreign-support-page";
import Ad from "./views/ad";

import ApisInfo from "./views/page-apis";
import Footer from "./components/footer";
import GithubHome from "./views/page-github-home";
import LogoList from "./views/logo-list";
import UiInfo from "./views/ui-info";
import FirstInfo from "./views/first-info";
import ChangeUI from "./views/change-ui";

function App() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  function isChineseEnv() {
    return (
      navigator.language?.toLocaleLowerCase()?.indexOf("zh") === 0 &&
      !window.location.href.includes("lang=en_us")
    );
  }

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      if (isChineseEnv()) {
        localStorage.setItem("lang", "zh_cn");
        i18n.changeLanguage("zh_cn");
      } else {
        localStorage.setItem("lang", "en_us");
        i18n.changeLanguage("en_us");
      }
    }
    i18n.changeLanguage(localStorage.getItem("lang") as string);

    // 关闭加载动画条
    (window as any).closeLoadingContainer();
  }, []);

  return (
    <div className={style.App}>
      <Header></Header>
      <main>
        <Index></Index>
        <DownloadPage></DownloadPage>
        {language === "zh_cn" && (
          <>
            <LogoList />
            <Ad />
          </>
        )}
        <div style={{ maxWidth: "900px", margin: "auto", padding: "12px" }}>
          <Divider></Divider>
        </div>
        <FirstInfo></FirstInfo>
        <UiInfo></UiInfo>
        <ChangeUI></ChangeUI>
        <ApisInfo></ApisInfo>
        <GithubHome type={1}></GithubHome>
        {language === "zh_cn" ? (
          <SupportPage></SupportPage>
        ) : (
          <ForeignSupportPage></ForeignSupportPage>
        )}
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
