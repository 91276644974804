// import { useTranslation } from "react-i18next";

function Index() {
  // const { t } = useTranslation();

  return (
    <></>
    // <Page id="about" className={style.container} type={0}>
    //   <div></div>
    // </Page>
  );
}

export default Index;
