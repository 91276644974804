import style from "./css/index.module.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Page from "../components/page";
import panelImage from "../images/panel/card-terminal.jpg";
import panelQuickStart from "../images/panel/card-quickstart.jpg";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { PanelCard } from "../components/panel-card";

export default function FirstInfo() {
  const { t, i18n } = useTranslation();

  return (
    <Page className={style.container} type={1}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <div style={{ textAlign: "center" }}>
            <div style={{ maxWidth: "480px", margin: "auto" }}>
              <div style={{ textAlign: "left" }}>
                <Typography
                  className={style.highlightGreen}
                  variant="h4"
                  gutterBottom
                  component="div"
                  dangerouslySetInnerHTML={{ __html: t("firstInfo.title") }}
                ></Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ position: "relative" }}>
            <div className="imageWrapper">
              <img
                src={panelImage}
                alt=""
                width="100%"
                height="420px"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div
              className="imageWrapper shakeAnimation"
              style={{ position: "absolute", right: "10%", top: "10%" }}
            >
              <img
                src={panelQuickStart}
                alt=""
                width="200px"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}
