import style from "./css/index.module.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "../components/page";
import { useTranslation } from "react-i18next";
import { PanelCard } from "../components/panel-card";
import card1ZH from "../images/panel/card1-zh.jpg";
import card2ZH from "../images/panel/card2-zh.jpg";
import card3ZH from "../images/panel/card3-zh.jpg";
import card1 from "../images/panel/card1.jpg";
import card2 from "../images/panel/card2.jpg";
import card3 from "../images/panel/card3.jpg";
import { CSSProperties } from "react";

const cardMap: any = {
  1: {
    zh_cn: card1ZH,
    en: card1,
  },
  2: {
    zh_cn: card2ZH,
    en: card2,
  },
  3: {
    zh_cn: card3ZH,
    en: card3,
  },
};

export function ShowCard({
  lang,
  id,
  style,
}: {
  lang: string;
  id: number;
  style?: CSSProperties;
}) {
  return (
    <PanelCard style={style} className="dance-2">
      <img
        alt=""
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src={cardMap[id][lang === "zh_cn" ? "zh_cn" : "en"]}
      />
    </PanelCard>
  );
}

function UiInfo() {
  const { t, i18n } = useTranslation();

  return (
    <Page className={style.container} type={1}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={6}>
          <div
            style={{
              position: "relative",
              height: "300px",
              marginBottom: "80px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-20px",
                right: "0px",
                transform: "rotate(15deg)",
              }}
            >
              <ShowCard
                lang={i18n.language}
                id={2}
                style={{ height: "200px", width: "365px" }}
              ></ShowCard>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "-20px",
                left: "0px",
                transform: "rotate(-10deg)",
              }}
            >
              <ShowCard
                lang={i18n.language}
                id={3}
                style={{ height: "130px", width: "336px" }}
              ></ShowCard>
            </div>
            <div
              style={{
                position: "absolute",
                top: "60px",
                left: "45%",
                transform: "translateX(-50%)",
              }}
            >
              <ShowCard
                lang={i18n.language}
                id={1}
                style={{ height: "150px", width: "360px" }}
              ></ShowCard>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ textAlign: "center" }}>
            <div style={{ maxWidth: "480px", margin: "auto" }}>
              <div style={{ textAlign: "left" }}>
                <Typography
                  className={style.highlightGreen}
                  variant="h4"
                  gutterBottom
                  component="div"
                  dangerouslySetInnerHTML={{ __html: t("leftInfo.UI") }}
                ></Typography>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default UiInfo;
