import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { IBaseProps } from "../services/interface";
import exampleCert from "../images/example_cert.png";

interface IProps extends IBaseProps {
  visible: boolean;
  onClose(): void;
}

interface IQuestions {
  question: string;
  answerKeywords: string[];
  value: string;
}

function QuestionPage(props: { onSubmit: (value: IQuestions[]) => void }) {
  const [questions, setQuestions] = useState<IQuestions[]>([
    {
      question: "域名 localhost 代表的是什么？",
      answerKeywords: [
        "本地",
        "本机",
        "当前",
        "这台",
        "回环",
        "此电脑",
        "我的电脑",
        "此机器",
        "我的机器",
        "我的电脑",
        "IP",
        "127.0.0.1",
        "::1",
      ],
      value: "",
    },
    {
      question: "Minecraft Java 版服务器默认的端口是？",
      answerKeywords: ["25565"],
      value: "",
    },
  ]);

  return (
    <div>
      <Typography variant="h6" color="initial" component="h5">
        请回答以下问题
      </Typography>
      {questions.map((question, index) => {
        return (
          <div style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" component="div" gutterBottom>
              {question.question}
            </Typography>
            <TextField
              label="请输入答案"
              style={{ width: "400px" }}
              defaultValue={question.value}
              size="small"
              onChange={(e) => {
                const newQuestions = [...questions];
                newQuestions[index].value = e.target.value;
                setQuestions(newQuestions);
              }}
            />
          </div>
        );
      })}
      <Button
        variant="outlined"
        style={{ marginRight: "10px" }}
        onClick={() => props.onSubmit(questions)}
      >
        确定
      </Button>
      <Button variant="outlined" onClick={() => props.onSubmit(questions)}>
        我不知道
      </Button>
    </div>
  );
}

function QQPage(showGroup: { title: string; subTitle: string; addr: string }) {
  return (
    <Grid container spacing={2} style={{ marginBottom: "20px" }}>
      <Grid item md={12} style={{ textAlign: "center" }}>
        {/* <div>
          <Typography variant="h6" color="initial" component="h5">
            {showGroup.title}
          </Typography>
        </div> */}
        <div
          style={{
            height: "100px",
            marginTop: "18px",
          }}
        >
          <Typography variant="subtitle1" color="initial" width={500}>
            {showGroup.subTitle}
          </Typography>
        </div>
        <div style={{ marginTop: "16px" }}>
          <a href={showGroup.addr} target="_blank" rel="noopener noreferrer">
            <Button variant="outlined">
              <Typography variant="body1" align="center">
                加入
              </Typography>
            </Button>
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

interface QQGroupInfo {
  title: string;
  subTitle: string;
  addr: string;
}

function Index(props: IProps) {
  const groups: Record<string, QQGroupInfo | undefined> = {
    normal: {
      title: "普通用户交流群",
      subTitle:
        "欢迎加入 MCSManager 用户群，本群不提供免费无偿的客服解决问题，只提供平台互相交流。",
      addr: "https://jq.qq.com/?_wv=1027&k=Gw26ZJnm",
    },
    advanced: {
      title: "高级用户交流群",
      subTitle:
        "您正确的回答了问题，我们将认为您已经是有计算机基础的业余开发者，运维人员等，本群不提供解决任何基础问题。",
      addr: "https://jq.qq.com/?_wv=1027&k=LswdnCYS",
    },
  };

  useEffect(() => {
    const groupLevel = localStorage.getItem("groupLevel");
    if (groupLevel) {
      setCurrentGroup(groups[groupLevel]);
      setGroupLevel(groupLevel);
    }
  }, []);

  const [currentGroup, setCurrentGroup] = useState<QQGroupInfo>();
  const [groupLevel, setGroupLevel] = useState("");

  const changeGroupLevel = (level: string) => {
    localStorage.setItem("groupLevel", level);
    setCurrentGroup(groups[level]);
    setGroupLevel(level);
  };

  const handleSubmitQuestion = (params: IQuestions[]) => {
    let isOK = false;
    params.forEach((item) => {
      for (const text of item.answerKeywords) {
        if (
          item.value.toLowerCase().trim().includes(text.toLowerCase().trim())
        ) {
          isOK = true;
          break;
        }
      }
    });
    if (isOK) {
      changeGroupLevel("advanced");
    } else {
      changeGroupLevel("normal");
    }
  };

  return (
    <div>
      <Dialog open={props.visible} onClose={props.onClose} maxWidth="md">
        <DialogTitle>申请加入 QQ 群</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {!groupLevel && <QuestionPage onSubmit={handleSubmitQuestion} />}
            {groupLevel && currentGroup && (
              <QQPage
                subTitle={currentGroup?.subTitle}
                title={currentGroup?.title}
                addr={currentGroup?.addr}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
