import { IBaseProps } from "../services/interface";
import style from "./css/panel.module.css";

export function PanelCard(props: IBaseProps) {
  return (
    <div
      className={[
        style.panelCard,
        "imageWrapperAction",
        ...(props.className || "")?.split(" "),
      ].join(" ")}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
