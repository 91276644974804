import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "@mui/material";
export function LinkTo(props: { addr?: string; t: string }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <ArrowForwardIcon
        style={{
          color: "#1976d2",
          fontWeight: 400,
          fontSize: "16px",
          marginRight: "2px",
          marginLeft: "0px",
        }}
      ></ArrowForwardIcon>
      <Link
        underline="none"
        href={props.addr}
        variant="body1"
        target="_blank"
        style={{ fontWeight: 400 }}
      >
        {props.t}
      </Link>
    </div>
  );
}
