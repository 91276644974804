import style from "./css/index.module.scss";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import Page from "../components/page";
import { GoldSponsor } from "../components/gold-sponsor";
function LogoList() {
  return (
    <Page
      className={style.container}
      type={0}
      containerStyle={{ marginTop: "40px" }}
    >
      <div style={{ maxWidth: "1100px", margin: "auto", textAlign: "left" }}>
        <Typography variant="h4" gutterBottom component="div">
          金牌赞助
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          className="text-child-underline"
        >
          仅限<span>中国大陆地区</span>，“赞助”
          指无偿的为本项目提供资金，技术，服务上的支持。
        </Typography>

        <div style={{ margin: "0px -24px" }}>
          <GoldSponsor></GoldSponsor>
        </div>
        <Link
          href="#about"
          variant="h6"
          style={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "center",
          }}
        ></Link>
      </div>
    </Page>
  );
}

export default LogoList;
