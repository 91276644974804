import { useTranslation } from "react-i18next";
import changeUIPanelEN from "../images/panel/change-ui-en.png";
import changeUIPanelZH from "../images/panel/change-ui-zh.png";
import Typography from "@mui/material/Typography";
import Page from "../components/page";
import style from "./css/index.module.scss";

export default function ChangeUI() {
  const { t, i18n } = useTranslation();

  return (
    <Page className={style.container} type={0}>
      <Typography variant="h3" gutterBottom component="div" fontWeight={400}>
        <span
          className="tf-text-wrapper tf-text-3"
          dangerouslySetInnerHTML={{ __html: t("changeUiTitle") }}
        ></span>
      </Typography>
      <Typography variant="h6" gutterBottom component="div">
        {t("changeUiSubTitle")}
      </Typography>
      <div style={{ position: "relative" }}>
        <div
          style={{
            marginTop: "40px",
            height: "100%",
            width: "1200px",
            margin: "auto",
          }}
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={i18n.language === "zh_cn" ? changeUIPanelZH : changeUIPanelEN}
            alt=""
          />
        </div>
      </div>
    </Page>
  );
}
