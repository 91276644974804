import style from "./css/index.module.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "../components/page";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { LinkTo } from "../components/link-to";
import { CopyToClipboard } from "../utils/tools";
import { useSnackbar } from "notistack";
import QQGroupDialog from "../components/qq-group-dialog";
import { useState } from "react";

function PageDownload() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [visibleQQDialog, setVisibleQQDialog] = useState(false);

  const openQQGroupDialog = () => {
    setVisibleQQDialog(true);
  };

  const language = i18n.language;
  const chineseVersionAddr = `https://awwa.cc/mcsm/win`;
  const englishVersionAddr = "https://awwa.cc/mcsm/win";
  let installScript = `sudo su -c "wget -qO- https://script.mcsmanager.com/setup.sh | bash"`;
  if (i18n.language === "zh_cn") {
    installScript = `sudo su -c "wget -qO- https://script.mcsmanager.com/setup_cn.sh | bash"`;
  }
  let daemonDocAddr =
    "https://docs.mcsmanager.com/#/configuration/adding-daemon";
  if (i18n.language === "zh_cn") {
    daemonDocAddr =
      "https://docs.mcsmanager.com/#/zh-cn/tutorial/connect_daemon";
  }

  const githubIssuesAddr = "https://github.com/MCSManager/MCSManager/issues";
  const discordServerAddr = "https://discord.gg/BNpYMVX7Cd";
  const tgGroup = "https://t.me/MCSManager_dev";

  const copyLinuxInstallCommand = () => {
    CopyToClipboard(installScript);
    enqueueSnackbar(t("common.success"), {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
  };

  return (
    <div id="quickstart">
      <QQGroupDialog
        visible={visibleQQDialog}
        onClose={() => setVisibleQQDialog(false)}
      ></QQGroupDialog>
      <Page
        type={0}
        className={style.container}
        containerStyle={{ marginTop: "140px" }}
      >
        <Typography variant="h4" gutterBottom component="div">
          <span className="tf-text-wrapper tf-text-2 tf-font">
            {t("download.now")}
          </span>
        </Typography>
        <div
          style={{
            marginTop: "20px",
            maxWidth: "1100px",
            margin: "auto",
          }}
        >
          <Typography variant="body1">{t("download.tip")}</Typography>
        </div>
        <div
          style={{
            maxWidth: "1100px",
            margin: "auto",
            marginTop: "80px",
            textAlign: "left",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div>
                <Typography variant="h4" gutterBottom component="div">
                  {t("download.linuxTitle")}
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                  {t("download.linuxTip")}
                </Typography>
                <div>
                  <pre className={style.script}>{installScript}</pre>
                </div>
                <Button
                  variant="text"
                  size="small"
                  onClick={copyLinuxInstallCommand}
                >
                  {t("download.copy")}
                </Button>
                <Link
                  href="https://github.com/MCSManager/MCSManager#linux"
                  target="_blank"
                  style={{ color: "black" }}
                >
                  <Button variant="text" size="small">
                    {t("download.linuxFailed")}
                  </Button>
                </Link>
              </div>
            </Grid>
            <Grid item md={12}>
              <div>
                <Typography variant="h4" gutterBottom component="div">
                  {t("download.windowsTitle")}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                  {t("download.windowsTip")}

                  <div style={{ marginTop: "12px" }}>
                    <Link
                      underline="none"
                      href={
                        language === "zh_cn"
                          ? chineseVersionAddr
                          : englishVersionAddr
                      }
                      target="_blank"
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <Button color="info" variant="outlined">
                        {t("download.windowsDownloadBtn")}
                      </Button>
                    </Link>
                  </div>
                </Typography>
              </div>
            </Grid>
            <Grid item md={12}>
              <div>
                <div style={{ margin: "18px 0px 32px 0px" }}>
                  <Divider />
                </div>
                <Typography variant="h5" gutterBottom component="div">
                  {t("download.conectMutilServer")}
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                  {t("download.conectMutilServerTip")}
                </Typography>
                <div>
                  <LinkTo
                    addr={daemonDocAddr}
                    t={t("leftInfo.toApis")}
                  ></LinkTo>
                </div>
              </div>
            </Grid>

            <Grid item md={12} style={{ marginTop: "20px" }}>
              <div>
                <Typography variant="h5" gutterBottom component="div">
                  {t("download.otherProblem")}
                </Typography>
                {language === "zh_cn" && (
                  <div
                    style={{ marginTop: "6px", overflow: "hidden" }}
                    onClick={openQQGroupDialog}
                  >
                    <LinkTo t={t("download.orQQGroup")} />
                  </div>
                )}

                <div style={{ marginTop: "6px" }}>
                  <LinkTo
                    addr={githubIssuesAddr}
                    t={t("download.orGithubIssues")}
                  />
                </div>

                {language === "zh_cn" && (
                  <div style={{ marginTop: "6px" }}>
                    <LinkTo addr={tgGroup} t={t("download.orTGGroup")} />
                  </div>
                )}

                <div style={{ marginTop: "6px" }}>
                  <LinkTo
                    addr={discordServerAddr}
                    t={t("download.joinDiscord")}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Page>
    </div>
  );
}

export default PageDownload;
