import style from "./css/index.module.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Page from "../components/page";
import pageImage from "../images/page-githubdev.png";
import { useTranslation } from "react-i18next";
import { IBaseProps } from "../services/interface";

interface IProps extends IBaseProps {
  type?: number;
}

function PageGithubHome(props: IProps) {
  const { t, i18n } = useTranslation();

  return (
    <Page className={style.container} type={props.type ? props.type : 0}>
      <Typography
        className="pageTitle"
        variant="h3"
        gutterBottom
        component="div"
        fontWeight={400}
      >
        <span className="tf-text-wrapper tf-text-3">{t("github.title")}</span>
      </Typography>
      <Typography variant="h6" gutterBottom component="div">
        {t("github.subTitle")}
      </Typography>

      <div style={{ marginTop: "60px" }}>
        <div className={props.type ? "imageWrapper imageWrapperAction" : ""}>
          <img src={pageImage} alt="" width="920px" />
        </div>
      </div>
    </Page>
  );
}

export default PageGithubHome;
