import style from "./css/index.module.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Page from "../components/page";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { useState } from "react";
import { QQLink } from "../services/const";
import GoldSponsor from "../components/gold-sponsor";
interface ISponsor {
  name: string;
  price: number;
  message: string;
}

function Sponsor(sponsor: ISponsor) {
  return (
    <Card
      style={{
        textAlign: "left",
        height: "106px",
        border: "0px",
        backgroundColor: "initial",
      }}
      variant="outlined"
    >
      <CardContent>
        <Typography variant="body1" component="div" align="center">
          {sponsor.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          ￥{sponsor.price}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          sx={{ color: "GrayText" }}
          color="text.gray"
        >
          {sponsor.message}
        </Typography>
      </CardContent>
    </Card>
  );
}

function SupportPage() {
  const { t } = useTranslation();
  const [showAllSponsors, setShowAllSponsors] = useState(false);
  let sponsorList = (window as any).sponsorList() as ISponsor[];
  sponsorList = sponsorList.sort((a, b) => b.price - a.price);
  if (!showAllSponsors) {
    sponsorList = sponsorList.slice(0, 30);
  }

  const showBtn = showAllSponsors ? (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => setShowAllSponsors(false)}
    >
      {t("p3.narrow")}
    </Link>
  ) : (
    <Link
      style={{ cursor: "pointer" }}
      onClick={() => setShowAllSponsors(true)}
    >
      {t("p3.expend")}
    </Link>
  );

  return (
    <Page id="about" className={style.container} type={0}>
      <Typography variant="h3" gutterBottom component="div" fontWeight={400}>
        <span className="tf-text-wrapper tf-text-3">{t("foreign.title")}</span>
      </Typography>
      <Typography variant="h6" gutterBottom component="div">
        {t("p3.subTitle")}
      </Typography>
      <Typography variant="h6" gutterBottom component="div">
        {t("p3.subTitleToAfd")}
        &nbsp;
        <Link href="https://afdian.com/@mcsmanager" key="1">
          {t("p3.afd")}
        </Link>
        &nbsp;
        {t("or")}
        &nbsp;
        <Link href={QQLink} key="2">
          {t("p3.qqGroup")}
        </Link>
      </Typography>
      <div style={{ marginTop: "60px" }}>
        <div style={{ marginBottom: "30px" }}>{GoldSponsor()}</div>
        <div className={style.sponsorListContainer}>
          <Grid container spacing={0}>
            {sponsorList.map((v, index) => (
              <Grid item lg={2} md={2} xs={6} key={index}>
                {Sponsor(v)}
              </Grid>
            ))}
          </Grid>
          <div>{showBtn}</div>
        </div>
      </div>
    </Page>
  );
}

export default SupportPage;
