import style from "./css/footer.module.css";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

function Footer() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const friends = [
    {
      name: "MineBBS 论坛",
      link: "https://www.minebbs.com/",
    },
    {
      name: "Lazy's 主题博客",
      link: "https://www.lazy.ink/",
    },
    {
      name: "LL Town 服务器",
      link: "http://www.mcllxz.cc/",
    },
    {
      name: "BetaMC 论坛",
      link: "https://www.betamc.net/",
    },
  ];

  const friendElement = friends.map((v, index) => {
    return (
      <span key={index}>
        <Link href={v.link} target="_blank" color="inherit">
          {v.name}
        </Link>
        &nbsp;&nbsp;
      </span>
    );
  });

  return (
    <footer className={style.wrapper}>
      <div className={style.container}>
        <Typography variant="caption" gutterBottom component="p">
          {t("footer.line1")}
        </Typography>
        {language === "zh_cn" && (
          <Typography variant="caption" gutterBottom component="p">
            <Link
              href="https://beian.miit.gov.cn/"
              target="_blank"
              color="inherit"
            >
              {t("footer.beiAn")}
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {t("footer.friendLink")}
            &nbsp;&nbsp;
            {friendElement}
          </Typography>
        )}
        {/* <Typography variant="caption" gutterBottom component="p">
          Email: mcsmanager-dev@outlook.com
        </Typography> */}
      </div>
    </footer>
  );
}

export default Footer;
