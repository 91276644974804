import style from "./css/index.module.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Page from "../components/page";
import panelImage from "../images/pane_files.png";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

function PageApis() {
  const { t, i18n } = useTranslation();
  const isChinese = i18n.language === "zh_cn";

  const apiCode = `// HTTP API
// Get Instance Info
GET /api/instance?uuid={...}&daemon_id={...}

// Create Instance
POST /api/instance?daemon_id={...}
Body: ...More...

// Update Instance Settings
PUT /api/instance?daemon_id={...}
Body: ...More...

// Start Instance
GET /api/protected_instance/open?uuid={...}&daemon_id={...}`;

  return (
    <Page className={style.container} type={1}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <div style={{ textAlign: "center" }}>
            <div style={{ maxWidth: "480px", margin: "auto" }}>
              <div style={{ textAlign: "left" }}>
                <Typography
                  className={style.highlightGreen}
                  variant="h4"
                  gutterBottom
                  component="div"
                  dangerouslySetInnerHTML={{ __html: t("leftInfo.title") }}
                ></Typography>

                <Typography
                  className="text-child-underline"
                  variant="h4"
                  gutterBottom
                  component="div"
                  dangerouslySetInnerHTML={{ __html: t("leftInfo.title2") }}
                ></Typography>
                <br />
                <Link
                  href="https://docs.mcsmanager.com/#/panel/overview"
                  variant="h6"
                >
                  {t("leftInfo.toApis")}
                </Link>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <div className="imageWrapper imageWrapperAction">
            <img src={panelImage} alt="" width="100%" />
          </div> */}
          <div style={{ textAlign: "left" }}>
            <Typography variant="body1">
              <pre className="code-area">{apiCode}</pre>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default PageApis;
